<template>
    <div class="pathology">
        <div class="title_box">
            <p class="title3">{{title}}</p>
            <p class="title_tip3">TRACE EVIDENCE IDENTIFICATION</p>
        </div>
        <div class="center_content bold">
            《声像资料司法鉴定执业分类规定》司法部司规［2020］5号
        </div>
        <div class="space_content" style="margin-bottom:2rem;">
           电子数据鉴定是指鉴定人运用信息科学与技术和专门知识，对电子数据的存在性、真实性、功能性、相似性等专门性问题进行检验、分析、鉴别和判断并提供鉴定意见的活动。

        </div>
        <div style="margin:2rem 0" class="flex wrap">
          <div class="col3 flex center" style="margin-bottom:2rem;" v-for="(item,index) in data" :key="index">
            <div class="col5 hvr-forward">
              <div style="margin-bottom:1rem;">
                <img :src="require('../../assets/range/electronic/'+item[0])">
              </div>
              <p> 
                {{(index-0+1)+'.'+item[1]}}
              </p>
            </div>
          </div>
        </div>
        <div class="flex wrap" style="margin-bottom:2rem;">
          <div class="col3">
            <p class="m bold col8" style="margin:0 auto">手机解锁取证</p>
            <div class="content_ col8" style="margin:1rem auto">
              福建历思司法鉴定所能够综合运用多种技术手段，支持破解、清除手机数字密码锁及手机图形锁等多种手机锁。对手机进行数据的恢复和固定、提取 。

            </div>
          </div>
          <div class="col3">
            <div class="col8" style="margin:0 auto">
              <img :src="require('../../assets/range/electronic/2-1.jpg')">
            </div>
          </div>
          <div class="col3">
            <p class="m bold col8" style="margin:0 auto">手机、平板、PC等电子数据恢复
</p>
            <div class="content_ col8" style="margin:1rem auto">
              历思拥有先进的电子数据取证工作站及硬盘镜像克隆系统，支持各类储存介质的数据恢复，能够针对不同的储存介质制定不同的数据恢复方案，最大限度地还原储存介质本身的数据。


            </div>
          </div>
        </div>
        
        <ConnectBtn></ConnectBtn>
    </div>
</template>
<script>
import mixin from "./mixin.js"
export default {
  mixins:[mixin],
  data(){
    return{
      data:[
        ['1-1.jpg','电子数据存在性鉴定'],
        ['1-2.jpg','电子数据真实性鉴定'],
        ['1-3.jpg','电子数据相似性鉴定'],
        ['1-4.jpg','电子数据功能性鉴定'],
    
      ]
    }
  }
}
</script>
<style scoped>
.content_{
  text-align:left;
  text-indent:2rem;
}
</style>
